import { useEffect, useState } from "react";
import {
  Dialog,
  Stack,
  TextField,
  Container,
  Button,
  Typography,
  DialogContent,
  CircularProgress,
  Box,
  SxProps,
} from "@mui/material";
import { Send, Check, TaskAlt } from "@mui/icons-material";
import { green } from "@mui/material/colors";

import axios, { AxiosError, AxiosResponse } from "axios";
import Role from "../models/Role";
import * as EmailValidator from "email-validator";
import Cookies from "js-cookie";

export type CalculatorValues = {
  annualLotOrHomeSales: number;
  tscTimeSavedHours: number;
  tscTimeSavedSalary: number;
};

function ContactForm(props: {
  closeEvent: () => void;
  open: boolean;
  roles: Role[];
  calculatorValues: CalculatorValues;
}) {
  const [inProgress, setInProgres] = useState(false);
  const [infoSent, setInfoSent] = useState(false);
  const [exception, setException] = useState<string | undefined>(undefined);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [hubspotutk, setHubspotutk] = useState<string | undefined>(undefined);

  // accessing the Hubspot Cookies
  useEffect(() => {
    setHubspotutk(Cookies.get("hubspotutk"));
  }, []);

  // auto-fill the contact form if hubspot contact returned
  useEffect(() => {
    if (hubspotutk) {
      axios
        .get(
          `.netlify/functions/hubspot-query-contact?hubspotutk=${hubspotutk}`,
          { timeout: 30000 }
        )
        .then((res: AxiosResponse) => {
          syncUserInfo(res.data);
        })
        .catch((err: AxiosError) => {
          console.log("Non-existing contact or other eceptions");
        });
    }
  }, [hubspotutk]);

  const syncUserInfo = (data: { contactInfo?: { properties?: any } }) => {
    if (data.contactInfo?.properties) {
      const { email, firstname, lastname } = data.contactInfo?.properties;
      email && setEmail(email.value);
      firstname && setFirstName(firstname.value);
      lastname && setLastName(lastname.value);
    } else {
      console.log("No existing contact info.");
    }
  };

  const sendContactInfo = () => {
    if (firstName === "" && lastName === "") {
      setException("Please input your name and resend.");
      return;
    }
    if (!EmailValidator.validate(email)) {
      setException(
        "The Email format is invalid, please correct it and resend."
      );
      return;
    }

    setInProgres(true);
    setException(undefined);

    const hubspotUri =
      ".netlify/functions/hubspot-submit-form?" +
      `firstname=${firstName}&lastname=${lastName}&email=${email}` +
      `&annual_lot_or_home_sales=${
        props.calculatorValues.annualLotOrHomeSales
      }&tsc_time_saved_hours=${
        props.calculatorValues.tscTimeSavedHours
      }&tsc_time_saved_salary=${
        props.calculatorValues.tscTimeSavedSalary
      }&time_saver_role_values=${composeRoleValues(props.roles)}` +
      (hubspotutk ? `&hubspotutk=${hubspotutk}` : "");

    axios
      .get(hubspotUri, { timeout: 5000 })
      .then((res) => {
        setInProgres(false);
        setInfoSent(true);
      })
      .catch((error) => {
        if (error.response?.data?.error?.body?.message) {
          if (
            (error.response.data.error.body.message as string).indexOf(
              "INVALID_EMAIL"
            )
          ) {
            setException(
              "The Email format is invalid, please correct it and resend."
            );
          } else {
            setException(error.response?.data?.error?.body?.message);
          }
        } else {
          setException(
            "Server Error, please wait a second and resend or refesh this page."
          );
        }
        setInProgres(false);
        console.log(error.response);
      });
  };

  const sendButtonSx: SxProps = {
    ...(infoSent && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.closeEvent}
      sx={{ borderColor: "blue" }}
    >
      {infoSent ? (
        <Container>
          <Stack direction="column">
            <Box sx={{ margin: "5px auto 5px auto", fontSize: "30px" }}>
              {" "}
              <Typography variant="body1" padding="10px" textAlign="center">
                Thanks for trying our time saver calculator.
                <br /> A member of our sales team will follow up with you within
                24 hours.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <TaskAlt sx={{ color: "#1976d2", fontSize: "90px" }} />
            </Box>
            <Box sx={{ margin: "5px auto 5px auto" }}>
              <Button variant="outlined" onClick={props.closeEvent}>
                Close
              </Button>
            </Box>
          </Stack>
        </Container>
      ) : (
        <Container>
          <Stack direction="column">
            <Box sx={{ margin: "5px auto 5px auto" }}>
              <Typography variant="h5">Contact Info</Typography>
            </Box>
            <DialogContent dividers>
              <TextField
                margin="dense"
                className="contact-form-input"
                label="First Name"
                value={firstName}
                fullWidth
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              ></TextField>
              <TextField
                margin="dense"
                className="contact-form-input"
                label="Last Name"
                fullWidth
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              ></TextField>
              <TextField
                margin="dense"
                className="contact-form-input"
                label="E-Mail"
                fullWidth
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></TextField>
            </DialogContent>
            {exception && (
              <Box sx={{ color: "red", margin: "15px auto 15px auto" }}>
                {exception}
              </Box>
            )}
            <Box sx={{ margin: "15px auto 15px auto" }}>
              <Button
                variant="contained"
                endIcon={infoSent ? <Check /> : <Send />}
                disabled={inProgress}
                sx={sendButtonSx}
                onClick={() => {
                  sendContactInfo();
                }}
              >
                Send Contact Info
                {inProgress && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "silver",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Box>
          </Stack>
        </Container>
      )}
    </Dialog>
  );
}

function composeRoleValues(roles: Role[]) {
  let roleValues = "";
  roles.forEach((role) => {
    roleValues += `${role.title}:${role.checked ? role.hoursSpent : "OFF"}%0A`;
  });
  roleValues = roleValues.replace(/%0A$/, "");

  return roleValues;
}

export default ContactForm;
