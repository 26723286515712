import { useState, useEffect, forwardRef, Dispatch } from "react";
import {
  Divider,
  Typography,
  Stack,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";

import Role from "../models/Role";
import RoleBox from "./RoleBox";
import { CalculatorValues } from "./ContactForm";

import "./styles/RoleTable.css";

const RoleTable = forwardRef(
  (props: {
    roles: Role[];
    setRoles: (roles: Role[]) => void;
    budgetThisYear: number;
    calculatorValues: CalculatorValues;
    setCalculatorValues: Dispatch<CalculatorValues>;
  }) => {
    const [budgetThisYear, setBudgetThisYear] = useState(props.budgetThisYear);

    const handleOnCheckedEvent = (key: number): void => {
      const changedRoles = [...props.roles];
      changedRoles[key].checked = !changedRoles[key].checked;
      props.setRoles(changedRoles);
    };

    const handleOnHoursSpentChangeEvent = (
      key: number,
      value: number
    ): void => {
      const changedRoles = [...props.roles];
      changedRoles[key].hoursSpent = value ? value : 0;
      props.setRoles(changedRoles);
    };

    const onBudgetChangeEvent = (event: SelectChangeEvent) => {
      setBudgetThisYear(parseInt(event.target.value));
    };

    useEffect(() => {
      props.setRoles(
        props.roles.map((role) => {
          role.budgetThisYear = budgetThisYear;
          return role;
        })
      );

      props.setCalculatorValues({
        annualLotOrHomeSales: budgetThisYear,
        tscTimeSavedHours: Math.round(getTotal(props.roles, "maxHours")),
        tscTimeSavedSalary:
          Math.round(getTotal(props.roles, "maxSalary") / 10) * 10,
      });
    }, [props.roles, budgetThisYear, props]);

    return (
      <Box
        id="main-box"
        sx={{
          margin: "auto",
          maxWidth: "60vw",
          boxShadow: "0px 6px 14px -6px rgba(0,0,0,0.41)",
          borderRadius: "15px",
          padding: "10px",
          backgroundColor: "#FBFBFB",
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
        }}
      >
        <Stack
          id="title-group"
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <img
            id="left-banner"
            src="images/LotWorksLogo.png"
            alt="LotWorks Time Saver Calculator"
          />
          <Typography
            id="title"
            variant="h1"
            fontWeight="800"
            paddingBottom="0.5vw"
            color="#192a55"
          >
            TIME SAVER CALCULATOR
          </Typography>
          <div style={{ visibility: "hidden" }} id="right-banner" />
        </Stack>
        <Box>
          <Typography
            id="timesaver-description"
            margin="auto"
            textAlign="left"
            variant="h3"
            fontWeight="bold"
            color="#235591"
          >
            LotWorks software automates repetitive tasks associated with selling
            land and homes — saving time and money.
          </Typography>
          <Typography
            id="timesaver-instruction"
            paddingTop="0.3vh"
            margin="auto"
            textAlign="left"
            variant="body2"
            fontSize="1.1vw"
            color="#666666"
          >
            Input how much time you spend on the everyday tasks listed below.
            You can un-check a role if it's not applicable to you.
          </Typography>
        </Box>
        <Stack id="select-stack" direction="row">
          <div
            style={{
              textAlign: "left",
              fontSize: "16px",
              paddingLeft: "47px",
              margin: "auto 0 ",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            }}
          >
            How many lots/homes will you sell this year?
          </div>
          <FormControl
            id="select-box"
            fullWidth
            variant="standard"
            sx={{
              m: 1,
            }}
          >
            <Select
              id="budget-select-standard"
              value={budgetThisYear.toString()}
              onChange={onBudgetChangeEvent}
              label="Budget"
            >
              <MenuItem sx={{ justifyContent: "center" }} value={100}>
                100
              </MenuItem>
              <MenuItem sx={{ justifyContent: "center" }} value={200}>
                200
              </MenuItem>
              <MenuItem sx={{ justifyContent: "center" }} value={300}>
                300
              </MenuItem>
              <MenuItem sx={{ justifyContent: "center" }} value={600}>
                600
              </MenuItem>
              <MenuItem sx={{ justifyContent: "center" }} value={1200}>
                1,200
              </MenuItem>
              <MenuItem sx={{ justifyContent: "center" }} value={2400}>
                2,400
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row">
          <Box>
            {" "}
            <Typography
              paddingLeft="45px"
              variant="body2"
              align="left"
              fontSize="18px"
              color="gray"
            >
              Role
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "auto" }}>
            <Typography variant="body2" fontSize="18px" color="gray">
              Hours/Month
            </Typography>
          </Box>
        </Stack>
        <Divider />
        {props.roles.map((role, key) => {
          return (
            <Stack key={key}>
              <RoleBox
                key={key}
                role={role}
                checked={props.roles[key].checked}
                onCheckedEvent={() => {
                  handleOnCheckedEvent(key);
                }}
                onHoursSpentChangedEvent={(value: number) => {
                  handleOnHoursSpentChangeEvent(key++, value);
                }}
              />
              <Divider />
            </Stack>
          );
        })}
        <Stack sx={{ maxWidth: "100vw" }}>
          <Typography
            className="result-lines"
            maxWidth="100vw"
            variant="inherit"
            paddingTop="15px"
            align="center"
          >
            LotWorks could help you save{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              <span className="result-value">
                {Math.round(getTotal(props.roles, "hoursSpent"))}
              </span>{" "}
              to{" "}
              <span className="result-value">
                {Math.round(getTotal(props.roles, "maxHours"))}
              </span>
            </span>
            {" man-hours a month."}
            <br />
            Saving{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              <span className="result-value">
                {formatter.format(getTotal(props.roles, "min"))}
              </span>{" "}
              to{" "}
              <span className="result-value">
                {formatter.format(
                  Math.round(getTotal(props.roles, "maxSalary") / 10) * 10
                )}
              </span>
            </span>{" "}
            {" per month in wasted time."}
          </Typography>
          <Typography
            margin="auto"
            textAlign="center"
            width="40vw"
            variant="body2"
            fontSize="12px"
          >
            *Note: The calculations are based on industry standard rates.
          </Typography>
        </Stack>
      </Box>
    );
  }
);

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

function getTotal(roles: Role[], propName: keyof Role): number {
  let sum = 0;
  roles.forEach((role) => {
    if (role.checked) sum += role[propName] ? (role[propName] as number) : 0;
  });
  return sum;
}

export default RoleTable;
