import { TextField, Checkbox, Typography, Stack } from "@mui/material";

import Role from "../models/Role";

import "./styles/RoleBox.css";

function RoleBox(props: {
  role: Role;
  checked: boolean;
  onCheckedEvent: () => void;
  onHoursSpentChangedEvent: (value: number) => void;
}) {
  return (
    <Stack direction="row" color={props.checked ? "default" : "lightgray"}>
      <Stack justifyContent="top">
        <Checkbox
          sx={{ paddingTop: "5px" }}
          checked={props.checked}
          onClick={props.onCheckedEvent}
        />
      </Stack>
      <Stack sx={{ paddingTop: "5px" }} justifyContent="center">
        <Typography
          paddingLeft="5px"
          variant="body2"
          align="left"
          fontSize="16px"
        >
          {props.role.title}
        </Typography>
        <Typography
          paddingLeft="5px"
          paddingRight="20px"
          variant="caption"
          align="left"
          color={props.checked ? "gray" : "lightgray"}
          gutterBottom
          fontSize="12px"
        >
          {props.role.description}
        </Typography>
      </Stack>
      <Stack justifyContent="center" marginLeft="auto">
        <TextField
          variant="outlined"
          sx={{ minWidth: "50px", width: "8vw" }}
          inputProps={{
            style: { maxHeight: "10px", textAlign: "center", fontSize: "18px" },
          }}
          margin="dense"
          type="number"
          value={props.role.checked ? props.role.hoursSpent : 0}
          disabled={!props.role.checked}
          onChange={(event) => {
            // make the number is always bigger than -1
            props.onHoursSpentChangedEvent(
              !checkFloatFormat.test(event.target.value) ||
                parseFloat(event.target.value) < 0
                ? 0
                : parseFloat(event.target.value)
            );
          }}
        ></TextField>
      </Stack>
    </Stack>
  );
}

const checkFloatFormat = RegExp("^[0-9]*([.][0-9]*)?");

export default RoleBox;
