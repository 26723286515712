import { useState } from "react";

import { Box, Button, Container } from "@mui/material";
import { SmartDisplay, Refresh } from "@mui/icons-material";

import _ from "lodash";

import Role from "../models/Role";
import RoleTable from "./RoleTable";
import ContactForm, { CalculatorValues } from "./ContactForm";

let budgetThisYear = 600;

function Home() {
  const [managedRoles, setManagedRoles] = useState(_.cloneDeep(roles));
  const [showContactForm, setShowContactForm] = useState(false);
  const [calculatorValues, setCalculatorValues] = useState<CalculatorValues>({
    annualLotOrHomeSales: 0,
    tscTimeSavedHours: 0,
    tscTimeSavedSalary: 0,
  });

  return (
    <Container sx={{ padding: "10px" }}>
      <div>
        <RoleTable
          roles={managedRoles}
          setRoles={setManagedRoles}
          budgetThisYear={600}
          calculatorValues={calculatorValues}
          setCalculatorValues={setCalculatorValues}
        />
      </div>
      <Box padding="0px">
        <Button
          sx={{ margin: "15px" }}
          variant="contained"
          endIcon={<Refresh />}
          color="error"
          onClick={() => {
            setManagedRoles(_.cloneDeep(roles));
          }}
        >
          Reset
        </Button>
        <Button
          sx={{ margin: "15px" }}
          variant="contained"
          color="success"
          endIcon={<SmartDisplay />}
          onClick={() => {
            setShowContactForm(!showContactForm);
          }}
        >
          Request a Demo
        </Button>
      </Box>
      <ContactForm
        open={showContactForm}
        roles={managedRoles}
        calculatorValues={calculatorValues}
        closeEvent={() => {
          setShowContactForm(false);
        }}
      ></ContactForm>
    </Container>
  );
}

const roles: Role[] = [
  new Role(
    "Owner/Partner",
    "Hours spent on unnecessary meetings/emails/calls, gathering info to make key decisions, confirming accurate data, pulling together reports for investors",
    250000,
    6,
    0.04,
    budgetThisYear
  ),
  new Role(
    "VP Land",
    "Unnecessary meetings/emails/calls, comparing projects, evaluating land value, tracking and charting trailing weekly averages, gathering info to make key decisions",
    200000,
    6,
    0.06,
    budgetThisYear
  ),
  new Role(
    "Development Manager",
    "Unnecessary meetings/emails/calls, searching for correct milestone updates, communicating schedules with employees and partners, determining the timing of the next phase",
    140000,
    14,
    0.08,
    budgetThisYear
  ),
  new Role(
    "Finance/Accounting",
    "Trying to keep track of A/R, following up on late closings, unnecessary meetings/emails/calls, creating or updating reports, confirming accurate data across multiple sources",
    140000,
    16,
    0.09,
    budgetThisYear
  ),
  new Role(
    "EA/Sales Administrator",
    "Manually processing lot/home sales, redundant meetings/emails/calls, chasing accurate data, manually entering data from multiple sources and formats",
    80000,
    22,
    0.1,
    budgetThisYear
  ),
  new Role(
    "Legal/Entitlements/Conveyancing",
    "Manually processing legal documentation, unnecessary meetings/emails/calls, communicating with sales administration and partner builders, chasing accurate data",
    100000,
    8,
    0.09,
    budgetThisYear
  ),
  new Role(
    "Marketing",
    "Updating lot maps, chasing down accurate traffic data, creating or updating reports, sharing numbers with builder partners, calculating ROI for marketing efforts",
    90000,
    14,
    0.05,
    budgetThisYear
  ),
  new Role(
    "Architectural Manager",
    "Gathering home design submissions, communication churn with partner builders, wasting time on plans that don’t meet your guidelines, dealing with construction starts that are not approved",
    110000,
    18,
    0.1,
    budgetThisYear
  ),
  new Role(
    "Internal Home Builder Sales Staff",
    "Submitting homebuyer details, organizing model home traffic numbers, reviewing data, updating maps, keeping track of available inventory",
    150000,
    16,
    0.1,
    budgetThisYear
  ),
];

export default Home;
