export default class Role {
  static readonly TotalHours = 2080;
  static readonly Months = 12;

  constructor(
    public title: string,
    public description: string,
    public salary: number,
    public hoursSpent: number,
    public lotMultiplier: number,
    public budgetThisYear: number,
    public checked = true
  ) {}

  get min(): number {
    return (this.salary / Role.TotalHours) * this.hoursSpent;
  }

  get maxHours(): number {
    return (
      this.hoursSpent + (this.budgetThisYear / Role.Months) * this.lotMultiplier
    );
  }

  get maxSalary(): number {
    return (this.salary / Role.TotalHours) * this.maxHours;
  }
}
